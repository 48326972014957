const responses = {
  1: "https://nanasays.s3.amazonaws.com/AbsolutelyPositutely.mp3",
  2: "https://nanasays.s3.amazonaws.com/ACapitalNOIMeanThat.mp3",
  3: "https://nanasays.s3.amazonaws.com/AintNotSaintsDownHere.mp3",
  4: "https://nanasays.s3.amazonaws.com/AintThatSomeCrap.mp3",
  5: "https://nanasays.s3.amazonaws.com/ANoWithACapitalN.mp3",
  6: "https://nanasays.s3.amazonaws.com/HelloYes.mp3",
  7: "https://nanasays.s3.amazonaws.com/IDontBelieveInMaybe.mp3",
  8: "https://nanasays.s3.amazonaws.com/IDontCareForIt.mp3",
  9: "https://nanasays.s3.amazonaws.com/CrazyQuestions.mp3",
  10: "https://nanasays.s3.amazonaws.com/DontBotherWithThem.mp3",
  11: "https://nanasays.s3.amazonaws.com/DontDependOnIt.mp3",
  12: "https://nanasays.s3.amazonaws.com/DontTellMeThatIDoubtIt.mp3",
  13: "https://nanasays.s3.amazonaws.com/GiveMeABreakAskSomebodyElse.mp3",
  14: "https://nanasays.s3.amazonaws.com/GodForgivesAllThingsForever.mp3",
  15: "https://nanasays.s3.amazonaws.com/GodIsTheOnlyOneTrysNotToSayNo.mp3",
  16: "https://nanasays.s3.amazonaws.com/IHopeIHaveTheAnswersForYou.mp3",
  17: "https://nanasays.s3.amazonaws.com/ImSickOfThis.mp3",
  18: "https://nanasays.s3.amazonaws.com/IWokeUpWithYouOnMyMind.mp3",
  19: "https://nanasays.s3.amazonaws.com/IWouldntPlaceAnyBetsOnIt.mp3",
  20: "https://nanasays.s3.amazonaws.com/JustLetNatureHaveItsCourse.mp3",
  21: "https://nanasays.s3.amazonaws.com/ManForgetIt.mp3",
  22: "https://nanasays.s3.amazonaws.com/MissBitch.mp3",
  23: "https://nanasays.s3.amazonaws.com/NeverHappen.mp3",
  24: "https://nanasays.s3.amazonaws.com/NoYouCrazy.mp3",
  25: "https://nanasays.s3.amazonaws.com/SantaClauseAintComingNoMore.mp3",
  26: "https://nanasays.s3.amazonaws.com/ShitSmearedDoesItSmell.mp3",
  27: "https://nanasays.s3.amazonaws.com/ShucksLawdHaveMercy.mp3",
  28: "https://nanasays.s3.amazonaws.com/SitDownAndFigureItOutAndAll.mp3",
  29: "https://nanasays.s3.amazonaws.com/SorryThatsInMyHeartGodKnowsBest.mp3",
  30: "https://nanasays.s3.amazonaws.com/ThatsDefinitelyNo.mp3",
  31: "https://nanasays.s3.amazonaws.com/ThatsNotMyCupOfTea.mp3",
  32: "https://nanasays.s3.amazonaws.com/TheLordSeldomlySaysNo.mp3",
  33: "https://nanasays.s3.amazonaws.com/TheTruthWillSetYouFree.mp3",
  34: "https://nanasays.s3.amazonaws.com/ThinkAboutItTooMuchDontComeOut.mp3",
  35: "https://nanasays.s3.amazonaws.com/WeAllHaveAChoice.mp3",
  36: "https://nanasays.s3.amazonaws.com/WeAllHaveIssues.mp3",
  37: "https://nanasays.s3.amazonaws.com/WheneverYouGetSomethingYouGetIt.mp3",
  38: "https://nanasays.s3.amazonaws.com/WhyNot.mp3",
  39: "https://nanasays.s3.amazonaws.com/YesLawd.mp3",
  40: "https://nanasays.s3.amazonaws.com/YesMaam.mp3",
  41: "https://nanasays.s3.amazonaws.com/YouNeedMyHelpRightNowCounsilItOut.mp3"
};

export default responses;
